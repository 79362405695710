import React from 'react';

const FundraiserCTA = () => (
  <div
    id="how-you-can-help"
    className="outter-wrapper feature social-block centered"
  >
    <div className="main-container">
      <div className="main wrapper clearfix">
        <div className="main-content">
          <h4>
            Please donate to help Richard get back on his feet now that he has
            regained his much deserved freedom.
          </h4>
          <a
            href="https://www.gofundme.com/f/mmkb55-support-richard-delisis-reentry-fund"
            target="_blank"
            className="btn"
          >
            SUPPORT RICHARD'S RE-ENTRY
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default FundraiserCTA;
