import React from 'react';
import Feature from './feature';
import RichardStory from './richard-story';

const HomeTopSection = () => {
  return (
    <div className="outter-wrapper">
      <Feature />
      <div className="main-container">
        <div className="main wrapper clearfix">
          <div className="main-content">
            <div className="clearfix">
              <div className="full">
                <p className="lead">
                  Thank you to everyone who has shown support for Richard
                  DeLisi. He was released on December 8, 2020 to over 50 of his
                  friends and family waiting for him at South Bay Correctional
                  Facility. It would not have been possible if it wasn’t for the
                  many people who showed support over this long 32-year journey.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeTopSection;
