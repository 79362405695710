import React from 'react';

const Footer = () => {
  return (
    <>
      <footer className="outter-wrapper feature">
        <div className="wrapper">
          <div className="clearfix">
            <div className="foot left">
              <ul className="social-link">
                <li>
                  <a target="_blank" href="https://www.facebook.com/freedelisi">
                    <em className="icon-facebook-sign"></em>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/freedelisi"
                  >
                    <em className="icon-instagram"></em>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
