import React from 'react';
import releaseVideo from '../assets/richard-delisi-release.mp4';
import releaseVideoThumbnail from '../images/richard-delisi-release.jpg';

const Feature = () => {
  return (
    <div className="welcome-container">
      <div className={'video-container'}>
        <h1>RICHARD DELISI IS NOW FREE AFTER 32 YEARS</h1>
        <p className="lead">
          Richard DeLisi was released on December 8, 2020 after serving 32 years
          of a 90-year prison sentence. Prior to his release he was the longest
          serving prisoner in the United States for non-violent cannabis
          offenses. Richard is now 71 years old and is working on re-building
          his life.
        </p>
        <p className="lead">
          If you need any help or know a non-violent prisoner who has been
          sentenced for cannabis offenses please reach out to the{' '}
          <a href={'https://www.lastprisonerproject.org/'} target={'_blank'}>
            Last Prisoner Project
          </a>
          . They were pivotal in securing Richard's release and we hope they can
          do the same for many others.
        </p>

        <div className={'cta-row'}>
          <a
            href="https://www.lastprisonerproject.org/"
            target="_blank"
            className="btn"
          >
            CONTACT THE LAST PRISONER PROJECT
          </a>
        </div>
        <video
          src={releaseVideo}
          controls
          poster={releaseVideoThumbnail}
          className={'welcome-video'}
        />
      </div>
      <div className="release-image-container">
        <div className={'release-image-group'}>
          <div className="release-image release-image-1 hero" />
        </div>
        <div className={'release-image-group small'}>
          <div className="release-image release-image-2 small" />
          <div className="release-image release-image-3 small" />
        </div>
        <div className={'release-image-group small'}>
          <div className="release-image release-image-4 small" />
          <div className="release-image release-image-5 small" />
        </div>
      </div>
    </div>
  );
};

export default Feature;
