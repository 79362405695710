import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import DelisiosoLogo from '../images/delisioso-logo-white.svg';

const Header = ({ siteTitle }) => {
  return (
    <div className="outter-wrapper header-container">
      <div className="wrapper">
        <header className="clearfix">
          <a href="/" className="logo">
            FREE DELISI
            {/* <img src={Logo} alt="Logo" /> */}
          </a>
          <nav id="nav-wrap">
            <ul className="nav" id="nav">
              <li className="last delisioso">
                <a href="http://delisioso.com" target="_blank">
                  <img src={DelisiosoLogo} className={'delisioso-logo'} />
                </a>
              </li>
            </ul>
          </nav>
        </header>
      </div>
    </div>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
