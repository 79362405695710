import React from 'react';

const RichardStory = () => {
  return (
    <div id={'richards-story'} className="main-container">
      <div className="main wrapper clearfix">
        <div className="main-content">
          <div className="clearfix">
            <div className="full">
              <h1>RICHARD'S STORY</h1>
              <p>
                Richard DeLisi’s reputation as a likeable, loving, polite,
                generous family man dates back seven decades. Those who were
                blessed to be his friends were considered and treated like
                family. Whether it was taking in children whose parents did not
                adequately care for them, giving his employees, who became
                family, places to live when they had nowhere to stay, or
                bringing clothes and food to impoverished Colombians, Richard
                always has tried to help others. Richard has also been credited
                with being a positive influence for many of his friends. He has
                helped his friends gain sobriety, paid for his Vietnam veteran
                friend’s rehabilitation after he became addicted to heroin
                trying to cope with PTSD, and guided a teenage employee out of
                the “party life” and back home to his young child so he could be
                the type of father Richard told him he should be (a boy who grew
                up to serve our country as a true hero). These are just some of
                the examples of acts of kindness Richard was known for before
                prison. He has been described by almost everyone who knows him
                as a generous spirit who always took care of those he loved.
                That’s one of the reasons why incarceration has been so
                difficult for him. He has had to be on the receiving end of
                financial and emotional support for the last 32 years while
                everyday he thrives to give and better those around him in his
                faith based dorm where he is locked up.
              </p>
              <p>
                Bars are not barriers to being on the receiving end of love.
                Numerous family members and childhood friends have never stopped
                talking to Richard regularly, visiting him when they can and
                sending money when it’s feasible. If Richard were to be
                released, he would have substantial community support as he has
                had over 10 offers from friends and family for him to live with
                them. While many incarcerated lose their support system after
                some time, Richard’s support system has grown as his friends and
                family have never left his side and as more people learn about
                his unjust 90-year sentence for cannabis and have come to his
                aid.
              </p>
              <p>
                Over the last three decades of incarceration, Richard has made
                it his mission to continue performing good deeds for others.
                Richard has consistently participated in programs to better his
                own life and the lives of those around him. When Richard arrived
                at prison he was unable to read or write as a result of
                undiagnosed dyslexia that had haunted him since his childhood.
                Many struggles in life were rooted in Richard’s inability to
                read. When it would be his turn to read aloud to his classmates,
                he often would say something outrageous to deflect from his
                inability to read, the resulting punishment paled in comparison
                with his embarrassment over what at the time was cruelly called
                “retardation,” by a priest his mother went to ask for
                assistance. As a result of the priest’s advice, Richard was put
                in a mental hospital for about a year, the type of place where
                he as a 9 year old witnessed horrors straight out of One Flew
                Over the Cuckoo’s Nest.
              </p>
              <p>
                A childhood friend turned reading specialist, Ted Feimer, who
                had seen first hand the struggles endured by Richard at school
                as a result of his undiagnosed learning disability, identified
                phonics as a road that would lead Richard to literacy. He sent
                Richard phonics books, and with many hours of practice and Ted’s
                assistance, Richard learned to read and write. This newfound
                ability was one he had to share, Richard’s true joy in life
                comes from seeing others happy. Richard asked the education
                director at his prison for the opportunity to teach others to
                read using phonics, the way he had been taught. So a classroom
                was set up and those inmates who sought to better themselves
                through learning the often underappreciated skills of reading
                and writing were provided the same opportunity to read that
                Richard had been afforded. Formerly the reading classes at the
                prison did not use phonics and Richard knew others may need an
                alternative teaching method, as he had, so he taught them this
                method himself. The Acting Education Director, Andre Russel,
                stated in his 1994 letter urging for Richard’s release that, “We
                gave Richard a classroom with some six or seven men who were
                unable to read and write. We watched closely. Within months,
                Richard had each student progressing in reading and writing
                skills. It was most impressive. And, to be sure, quite unusual
                for the prison setting.” Further he stated that Richard was
                “motivated to improve himself and to help others.” Richard’s
                innovative technique and motivation held true to his core
                foundation of helping others despite the surrounding
                circumstances.
              </p>
              <p>
                Richard has articulated the elation experienced when men would
                come up and tell him that they’d written their mothers for the
                very first time. Since family and staying close with them has
                always been important to Richard, he felt such joy from being
                able to further his fellow inmate’s communications and
                connections with their families. Additionally, as a result of
                teaching these individuals to read, several of them went on to
                obtain their GED’s, this brought Richard immense pride. The
                sense of accomplishing such an important basic skill elevated
                these individuals in an otherwise dismal environment, giving
                them hope of what they could achieve on the outside. He
                described his reading classes as truly meaningful and “worth its
                weight in gold.” Not only did Richard go out of his way to 3
                teach these individuals to read, he also provided a service that
                lessens the chance an offender will commit another crime in the
                community upon release. A 2009 report by the American
                Correctional Association concluded that offenders who complete
                the{' '}
                <a
                  href="https://digitalcommons.brockport.edu/cgi/viewcontent.cgi?article=1184&context=ehd_theses"
                  target="_blank"
                >
                  GED program
                </a>{' '}
                in prison are 25% less likely to return to prison than offenders
                who do not obtain their GED.
              </p>
              <h1>The Crime/Offense</h1>
              <p>
                On September 19, 1988, Richard DeLisi was arrested and charged
                with Trafficking in Cannabis, Conspiracy to Traffic in Cannabis,
                and Violation of the Racketeering Influenced and Corrupt
                Organization Act (RICO). These charges resulted from Richard
                agreeing to assist with the smuggling of cannabis into Florida
                from Colombia. Richard, previously in 1975, was convicted of a
                RICO charge, for smuggling cannabis, as a result when he was
                released from prison a little over a year later he was in
                serious financial debt. The RICO law intended for Florida to
                stymie illegal activity by financially punishing offenders. The
                DeLisi case was Florida’s first chance to test the law and as a
                result they confiscated the brothers’ homes, cars, and mostly
                everything they owned besides their auto body shop. In addition
                to being stripped of all Richard had owned, he also owed over
                $50,000 in fees to the state as well as high attorney’s fees.
                Numerous times during this difficult period, Richard was
                approached by his old smuggling friend, J.J. White, who told
                Richard they could be wealthy again if they went back to
                smuggling cannabis. Richard wanted to only make money legally
                but because of his debts he eventually agreed to one last
                smuggling trip. As the{' '}
                <a
                  href="https://www.miaminewtimes.com/news/meet-the-americans-serving-life-in-jail-for-weed-6530339"
                  target="_blank"
                >
                  Miami New Times
                </a>{' '}
                article about Richard DeLisi stated, “His friend J.J., a pilot,
                was going to hook them up with one last planeload of 1,500
                pounds. And he was going to do it for free, without taking a
                cut, so the guys could pay off their attorneys’ fees, get
                themselves a new house, and bulk up their business.” What
                Richard did not know was that his friend J.J. White was a paid
                confidential informant for the Florida Department of Law
                Enforcement (FDLE) as well as numerous other government
                agencies.
              </p>
              <p>
                At trial in 1989, Richard’s attorney argued the defense of
                entrapment but was unsuccessful. While the Florida sentencing
                guidelines recommended Richard be sentenced to between 12 and 17
                years in prison, the judge decided to give him 3 consecutive 30
                year sentences totaling to a 90 year prison term. The judge’s
                reasons for the upward departure and imposition of the maximum
                possible sentence were twofold. First, the judge stated Richard
                and his brother were the ringleaders/organizers of the crime and
                second, because of an interview Richard did which was perceived
                as him bragging about making large amounts of money from
                cannabis smuggling. Richard had given an interview to Geraldo
                Rivera which was edited down to a short clip that was not
                reflective of his true character. Geraldo himself came to the
                trial, embraced Richard, and testified that he was a “remorseful
                victim of his own trade.” Many think there must have been
                violence, weapons, or another aggravating factor for the judge
                to sentence Richard to 90 years but that is not the case.
                Richard has no history of violence, while the newspapers at the
                time reported him as “armed and dangerous” he was never even
                accused of committing any acts of violence. Even while
                incarcerated, where violence occurs often, Richard has never
                participated. His friends have described him as a man who would
                never hurt anyone, in fact that’s why he refused to participate
                in the illicit industry of substances far more lucrative than
                cannabis where violence and death was the norm.
              </p>
              <h1>Richard's Incarceration</h1>
              <p>
                Throughout Richard’s over 32 years of incarceration, he
                committed himself to education, rehabilitation, and
                self-improvement. While some inmates try to just pass the time
                in prison, Richard was determined to use his time while
                incarcerated to better himself. Richard wrote in a 2008 letter
                to Governor Crist, “My faith in God gives me hope and joy for
                mercy, which has carried over into all my activities over the
                course of my incarceration. I have been committed to education,
                rehabilitation, hard work, and diligence while in prison, which
                makes me an excellent candidate for commutation of sentence.”
                Richard further added, “I have overcome many difficulties,
                including an inability to read or write due to severe dyslexia.
                I have been successful in not only analyzing my past behavior
                but in changing the reasons, therefore. I have formulated and
                implemented a positive plan for a fruitful, productive life. I
                have taken full advantage of every opportunity afforded me in
                prison and have been heralded by prison staff members as the
                ‘ideal inmate.’” In Richard’s 2008 Clemency application, he
                submitted many letters from prison staff members who agreed that
                Richard was the perfect candidate for an exercise of clemency
                power.
              </p>
              <p>
                In Richard’s three decades in prison, he only received 5
                disciplinary reports (DR’s), with the last one occurring in
                2005. These include a visiting violation, disobeying an order,
                unauthorized absence, disrespect to an official and a telephone
                violation. Assistant Warden Lawrence at South Bay Correctional
                Institution said, “all of these DR’s are counted as minor DR’s
                and a person who has been in prison that long with a total of 5
                DR’s says a lot about that individual.” He went on to state that
                “looking at the length of time in between and the types of DR’s,
                it’s relatively minor.” Further, he agreed that 5 DR’s for
                relatively minor disciplinary issues is outstanding for someone
                who has been incarcerated for over 3 decades. While the
                assistant warden stated there are people who must be kept in
                prison to protect the public, Richard was not one of those
                people. Regarding Richard’s release and him not being a threat
                to public safety, the assistant warden said, “oh yeah, he is one
                you will feel confident about, he’s just a nice good old man.”
                When Richard arrived to prison, he was given the highest level
                of classification, super-close, which is comparable to maximum
                security. Through his incarceration the last 32 years, he has
                successfully obtained the lowest classification level possible,
                besides a community classification level, minimum security.
                Richard stated, “I am not a violent man, demonstrated by my
                ‘lack’ of violent criminal history and excellent behavioral
                record in prison. Rather, I am responsible, quiet and committed
                to my family and community. I am not, in any sense of the word,
                a threat to our society. I have the capacity and determination,
                for my own and my family’s sake, to be a law abiding, concerned,
                productive citizen.”
              </p>
              <p>
                Beyond learning to read and write, Richard also used his time to
                complete more than 30 self-betterment classes. These courses
                range from substance abuse programs to faith centered classes to
                numerous skills training courses. Initially, Richard was often
                denied the opportunity to participate in programs due to the
                length of his prison sentence. However, Richard would not give
                up, he’d let the instructors get to know him and eventually
                they’d permit his attendance. Richard did not stop on his
                journey of self-improvement despite the many disappointments and
                family tragedies he faced. He detailed this in his 2008 letter,
                “Even after [my] denial of clemency in 1997 and 2002, I have
                continued to develop and improve my character; such resolve
                speaks volumes of my determination. I have not faltered,
                wavered, or done any act apart from continuing on the course I
                have set for myself when I came to prison, from which I will not
                depart.” When hope seemed lost, Richard persevered. Richard
                never gave up, while he faced constant disappointments regarding
                his appeals, suffered grave family tragedies and felt like
                giving up, he refused to give up hope.
              </p>
              <p>
                Staying positive and motivated whilst incarcerated was not easy
                for Richard, as life brought him much tragedy and sorrow.
                Richard’s three children were approximately 2, 3 and 10 years
                old when he was jailed. Their lives have been very difficult as
                they have tried to grow up while forming a relationship with
                their father behind bars. Richard tried his best to be a parent
                from prison, but his absence affected his children in real and
                substantial ways. Richard’s youngest child Steven passed away in
                2010. Steven had written to the Clemency Board two years earlier
                in 2008 about the in-person love and guidance he so desperately
                needed from his father, sadly he never got that opportunity.
                While Richard was grieving the loss of his son from prison while
                separated from his support system, he was able to connect his
                family with a church that was able to hold the services for his
                son. It had been just a few months before Steven’s passing that
                his mother, Richard’s second wife died suddenly. Then in 2018,
                Richard’s daughter, Ashley was in a horrific car accident. She
                suffered severe injuries resulting in a stroke, paralyzing half
                her body making her wheelchair bound. In addition to these
                tragic incidents, he also lost both his mother and father in the
                last ten years of his incarceration. In fact, it was just last
                year that the patriarch of the DeLisi family succumbed to old
                age, holding out hope until he was near a century that he would
                be reunited with his youngest son Richard. Despite the many
                tragedies Richard faced and risk factors of life within prison,
                Richard surmounted such adversity and became a better person.
                Now his priority is to spend as much time with family and
                friends as possible. He has already greatly enjoyed his
                grandchildren; some he had not even met prior to his release.
              </p>
              <h1>RICHARD’S UNJUST SENTENCE </h1>
              <p>
                Richard served more than 32 years incarcerated which was more
                than double the recommended sentence provided by Florida’s
                Sentencing Guidelines. When informed by one of Richard’s
                attorney’s that he was the longest serving non-violent cannabis
                offender in the country, Assistant Warden Lawrence, was shocked
                and went on to say “I’ve seen people that have come in with
                murder charges and sex charges that are doing 15 and 10 years.
                And this person dealing marijuana was stuck with 90 years, I
                think he did his time and more.”
              </p>
              <p>
                While the Florida Sentencing Guidelines recommended a prison
                sentence between 12 and 17 years, Richard was given the maximum
                term of years for each of the three charges, which amounted to a
                total of 90 years. While Richard’s judge may have intended to
                make sure he was given a harsh sentence, the sentencing trend
                for similarly situated offenders or worst offenders to Richard
                shows his sentence of 90 years was significantly disparate. A
                Florida based company, Technologies for Justice provided
                sentencing data that showed Richard’s sentence was not only
                harsh but it was substantially higher than all other similarly
                situated offenders since 1998. According to the Technologies for
                Justice resources, their Equity in Sentencing Analysis System
                (ESAS) produces data that’s consistent with the Rule of Law,
                equity, fairness and appropriate punishment for each individual.
                It is clear that Richard’s sentence was beyond what was required
                for punishment, justice, and accountability.
              </p>
              <p>
                ESAS provided sentencing data for all offenders sentenced for
                Racketeering (RICO) as their primary offense in Polk County
                since 1998. Richard was sentenced under RICO as his primary
                offense in Polk County. Since 1998, the most someone was
                sentenced to in Polk County for RICO was 11 years and the
                average these offenders received is 6.28 years. The average
                sentence for RICO cases in all of Florida since 1998 is 19.21
                years in state prison, with the longest sentence someone
                received from 1998 until 2020 for RICO was 38 years in state
                prison.
              </p>
              <p>
                While the facts of each case are different and can certainly
                affect the sentence an offender was given, Richard’s case did
                not contain such aggravating factors that he should have
                received this type of disparate sentence. In fact, some of these
                offenders who received less than 38 years in state prison for
                RICO additionally committed violent acts. When comparing
                Richard’s sentence to other offenders convicted marijuana
                trafficking, it is clear that Richard was severely over
                sentenced. Most marijuana trafficking offenders were given
                sentences much closer to the sentence Richard was recommended to
                have, between 12-17 years. Additionally, traffickers of much
                more harmful substances received substantially lesser sentences
                than the 90-year prison term Richard received.
              </p>
              <p>
                While Richard is accountable for his crimes, he is cognizant of
                the fact that cannabis has never resulted in a death and
                according to{' '}
                <a href="https://norml.org/laws/" target={'_blank'}>
                  NORML
                </a>{' '}
                is now legal in 33 states for medicinal use (another 14 with
                limited THC), the adult use of cannabis is legal in 11 states
                (as well as DC, the Northern Mariana Islands, and Guam) and has
                been decriminalized in 16 other states (and the U.S. Virgin
                Islands) as of July, 2020. Richard has lost loved ones to the
                opioid epidemic, yet he served a longer sentence than those who
                manufactured the pills that caused the deaths of his son and
                ex-wife as well as almost{' '}
                <a href="https://www.cdc.gov/drugoverdose/epidemic/index.html">
                  450,000
                </a>{' '}
                other Americans since 1999. Further, in 2016, Floridians voted
                to approve a constitutional amendment to legalize medical
                marijuana. This year, medical cannabis products are expected to
                bring in more than $800 million in profits in Florida. While
                Florida now brings in tax dollars from cannabis, Richard’s
                incarceration cost Florida taxpayers over $1,673,670.00.
              </p>
              <p>
                Incarcerating Richard came at a high cost without a real benefit
                in terms of public safety. While the incarceration of
                individuals who aren’t a threat to the public is wasteful of tax
                dollars it also needlessly wastes human lives. That’s one of the
                reasons why Richard has dedicated the remainder of his life to
                fight for freedom for the many incarcerated who also were
                severely over sentenced. Please stay tuned to learn how you can
                help!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RichardStory;
