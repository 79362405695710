import React from 'react';
import lastPrisonerProjectLogo from '../images/last-prisoner-project.png';
import DelisiosoLogo from '../images/delisioso-logo-white.svg';

const HomeCallToAction = () => {
  return (
    <div
      className="outter-wrapper feature paralax-block centered"
      data-stellar-background-ratio="1.5"
    >
      <div className="main-container partners">
        <div className="main wrapper clearfix">
          <div className="main-content">
            <a href="https://www.lastprisonerproject.org/" target={'_blank'}>
              <img
                src={lastPrisonerProjectLogo}
                alt={'The Last Prisoner Project'}
                className={'last-prisoner-project-logo'}
              />
            </a>
            <a href="http://delisioso.com" target={'_blank'}>
              <img
                src={DelisiosoLogo}
                alt={'Delisioso'}
                className={'delisioso-footer-logo'}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeCallToAction;
